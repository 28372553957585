import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import NotFound from "../views/NotFound.vue";
//--------------------------------------------
import DevicesList from "../views/DevicesList.vue";
import DevicesCamera from "../views/DevicesCamera.vue";
//--------------------------------------------
import DeviceImage from "../views/DeviceImage.vue";
import DeviceChart from "../views/DeviceChart.vue";
import Playback from "../views/Playback.vue";
//--------------------------------------------

Vue.use(VueRouter);

const routes = [
    {
        name: "Login",
        path: "/login",
        meta: { isPublic: true },
        component: Login
    },
    {
        name: "Logout",
        path: "/logout",
        meta: { isPublic: true },
        component: Logout
    },
    {
        name: "Home",
        path: "/",
        //component: Home
        component: DevicesList
    },
    {
        name: "NotFound",
        path: "*",
        component: NotFound
    },
    {
        name: "DevicesList",
        path: "/devices-list",
        component: DevicesList
    },
    {
        name: "DevicesCamera",
        path: "/devices-camera",
        component: DevicesCamera
    },
    {
        name: "DeviceChart",
        path: "/device-chart/:device_id/",
        component: DeviceChart
    },
    {
        name: "DeviceImage",
        path: "/device-image/:device_id",
        component: DeviceImage
    },
    {
        name: "Playback",
        path: "/playback/:device_id",
        component: Playback
    }
];

const router = new VueRouter({
    routes
});

export default router;
