<template>
    <div class="device-image">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <h1 class="p-3">タイムラプス</h1>
        <b-container fluid>
            <b-row>
                <b-col></b-col>
                <b-col><b-form-datepicker v-model="targetDate" class="mb-5" @input="dateChange()" :max="maxDate"></b-form-datepicker></b-col>
                <b-col>
                    <router-link :to="{ name: 'DeviceChart', params: { device_id: device_id }, query: { targetDate: targetDate } }" v-show="false">
                        <b-button variant="success" size="sm" class="mr-1">
                            <b-icon icon="graph-up"></b-icon>
                        </b-button>
                    </router-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3" v-for="(item, idx) in dispImages" :key="idx">
                    <b-card no-body class="mb-3" border-variant="dark" header-border-variant="dark">
                        <template #header>
                            <b-row>
                                <b-col cols="8" class="text-left">
                                    <h5 class="mb-0">{{ item.device_name }}</h5>
                                    <small>{{ item.date }}</small>
                                </b-col>
                            </b-row>
                        </template>
                        <b-card-body class="p-0">
                            <b-aspect :aspect="aspect">
                                <b-img fluid :src="item.jpgImg" v-b-modal.modalDeviceJpg @click="modalUpdate(idx)" />
                            </b-aspect>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-show="loadingMax > loadingCount">
                    <b-button block variant="outline-info" @click="imageLoad()">さらに表示</b-button>
                </b-col>
            </b-row>
        </b-container>
        <!-- =========================== -->
        <b-modal id="modalDeviceJpg" size="xl" hide-footer>
            <template #modal-title>
                <h5 class="mb-0">{{ modalDevice.name }}</h5>
                <h6 class="mb-0 small">{{ modalDevice.date }}</h6>
            </template>
            <b-aspect :aspect="aspect"><b-img fluid-grow :src="modalDevice.jpgImg"/></b-aspect>
        </b-modal>
        <!-- =========================== -->
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "DeviceImage",
    //========================================================
    data() {
        return {
            jwt: "",
            device_id: 0,
            device_name: "",
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            imageList: [],
            dispImages: [],
            dayImageList: [
                //{ jpgImg: "", date: "", device_id: "", device_name: "" }
            ],
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: ""
            },
            loadingCount: 0,
            loadingMax: 0,
            aspect: "16:9",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        if (!this.jwt) this.$router.push({ name: "Login" });
        if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
        //------------------------------------------
        if (this.$route.params.device_id) this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;
        //------------------------------------------
        await this.getDevices();
        await this.getImageList();
        await this.imageLoad();
        //------------------------------------------
    },
    //========================================================
    watch: {},
    //========================================================
    methods: {
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { id: this.device_id },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    if (response.data.json[0]) {
                        this.dbDevices = response.data.json[0];
                        this.device_id = this.dbDevices.id;
                        this.device_name = this.dbDevices.name;
                    }
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async getImageList() {
            this.dayImageList = [];
            this.loadingCount = 0;
            this.loadingMax = 0;
            this.dispImages = [];
            await this.axios({
                method: "GET",
                url: "/web/api/image-list/" + this.device_id + "/" + this.targetDate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(async response => {
                    this.imageList = response.data.json;
                    this.imageList = this.imageList.reverse();
                    this.dayImageList = [];
                    for (let idx in this.imageList) {
                        let dt = this.imageList[idx];
                        if (!dt) continue;
                        this.dayImageList.push({
                            jpgImg: "",
                            date: dt,
                            device_id: this.device_id,
                            device_name: this.device_name
                        });
                    }
                    this.loadingMax = this.dayImageList.length;
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async imageLoad() {
            let startCount = this.loadingCount;
            let endCount = this.loadingCount + 8;
            this.loadingCount = this.loadingCount + 8;
            for (let idx in this.dayImageList) {
                if (startCount <= idx && idx < endCount) {
                    this.dispImages.push(this.dayImageList[idx]);
                }
            }
            for (let idx in this.dispImages) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.dispImages[idx].device_id;
                    let jpgKey = this.dispImages[idx].date;
                    this.getImage(device_id, jpgKey, this.dispImages[idx]);
                }
            }
        },
        //====================================================
        async getImage(device_id, jpgKey, imgObj) {
            await this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/image/" + device_id + "/" + jpgKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    imgObj.jpgImg = window.URL.createObjectURL(response.data);
                    imgObj.date = response.headers["x-image-date"];
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        modalUpdate(idx) {
            this.modalDevice.id = this.dispImages[idx].device_id;
            this.modalDevice.name = this.dispImages[idx].device_name;
            this.modalDevice.date = this.dispImages[idx].date;
            this.modalDevice.jpgImg = this.dispImages[idx].jpgImg;
        },
        //====================================================
        async dateChange() {
            await new Promise(r => setTimeout(r, 500));
            await this.getImageList();
            await this.imageLoad();
        }
        //====================================================
    }
    //========================================================
};
</script>
