<template>
    <div class="CommonHeader">
        <b-navbar type="dark" variant="dark" fixed="top">
            <!-- ============ -->
            <b-button v-b-toggle.sidebar-1 class="p-0 mr-3">
                <b-icon icon="justify" class="mx-3"></b-icon>
            </b-button>
            <!-- ============ -->
            <b-navbar-nav class="ml-auto">
                <router-link to="/" class="text-white">
                    <b-navbar-brand>
                        <b-img :src="FukushinImg" rounded thumbnail width="150"></b-img>
                    </b-navbar-brand>
                    <!-- <b-navbar-brand class="font-weight-bold">SmartFeedingSystem</b-navbar-brand> -->
                </router-link>
            </b-navbar-nav>
            <!-- ============ -->
        </b-navbar>
        <b-sidebar id="sidebar-1" title="メニュー" backdrop shadow width="180px" bg-variant="dark" text-variant="light">
            <div class="p-1">
                <router-link to="/devices-list">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">デバイス リスト</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/devices-camera">
                    <b-button block variant="success">
                        <span class="text-white font-weight-bold">デバイス カメラ</span>
                    </b-button>
                </router-link>
            </div>
            <div class="p-1">
                <router-link to="/logout">
                    <b-button block variant="danger">
                        <span class="text-white font-weight-bold"> Logout </span>
                    </b-button>
                </router-link>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
export default {
    props: {
        //deviceId: Number
        //device_id: Number
    },
    data() {
        return {
            FukushinImg: require("@/assets/fukushin.png")
            //device_id: 100
        };
    },
    mounted() {},
    watch: {}
};
</script>
