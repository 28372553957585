<template>
    <div class="devices-list">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <h1 class="p-3" @dblclick="debugMode = !debugMode">デバイス リスト</h1>
        <!-- =============================================== -->
        <b-container fluid>
            <b-row>
                <b-col cols="4">
                    <b-form-group><b-form-input v-model="filter" type="search" placeholder="キーワードフィルター"></b-form-input></b-form-group>
                </b-col>
            </b-row>
            <b-row><b-col></b-col></b-row>
        </b-container>

        <div class="m-3">
            <b-table :sticky-header="tableCSS()" head-variant="dark" :fields="fields" :items="dbDevices" :filter="filter" :filter-included-fields="filterOn" responsive hover bordered>
                <template v-slot:cell(site_pref)="data">
                    <div class="text-left">
                        {{ data.item.site_pref }}
                    </div>
                </template>
                <template v-slot:cell(site_name)="data">
                    <div class="text-left">
                        {{ data.item.site_name }}
                    </div>
                </template>
                <template v-slot:cell(name)="data">
                    <div class="text-left">
                        {{ data.item.name }}
                        <b-icon icon="exclamation-circle-fill" variant="warning" v-show="chkAlive(data.item.alived_at)"></b-icon>
                    </div>
                </template>
                <template v-slot:cell(device_id)="data">
                    <router-link :to="{ name: 'DeviceChart', params: { device_id: data.item.id } }" v-show="debugMode">
                        <b-button size="sm" variant="success" class="m-1">
                            <b-icon icon="graph-up"></b-icon>
                        </b-button>
                    </router-link>
                    <router-link :to="{ name: 'DeviceImage', params: { device_id: data.item.id } }">
                        <b-button size="sm" variant="success" class="m-1">
                            <b-icon icon="grid3x3-gap"></b-icon>
                        </b-button>
                    </router-link>
                    <router-link :to="{ name: 'Playback', params: { device_id: data.item.id } }">
                        <b-button size="sm" variant="danger" class="m-1">
                            <b-icon icon="collection-play-fill"></b-icon>
                        </b-button>
                    </router-link>
                </template>
            </b-table>
        </div>
        <!-- =============================================== -->
    </div>
</template>

<style>
table.b-table > thead > tr > th,
table.b-table > tbody > tr > td {
    white-space: nowrap;
    vertical-align: middle;
    padding: 3px;
}
</style>

<script>
import JWT from "jsonwebtoken";
// @ is an alias to /src
export default {
    name: "DevicesList",
    components: {},
    //========================================================
    data() {
        return {
            debugMode: false,
            jwt: "",
            myInfo: [],
            fields: [
                { key: "id", label: "ID", sortable: true },
                { key: "site_pref", label: "所在地", sortable: true },
                { key: "site_name", label: "組織名", sortable: true },
                { key: "name", label: "デバイス名", sortable: true },
                { key: "device_id", label: "リンク", sortable: false },
                { key: "alived_at", label: "アライブ", sortable: true }
            ],
            dbSites: [],
            dbDevices: [],
            dbLastSensings: [],
            filter: null,
            filterOn: ["name", "site_name", "site_pref"],
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            //modalDevice: { id: undefined, name: "", mac: "", rtc_interval: 29, site_id: undefined, memo: "" },
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: ""
            },
            aspect: "16:9",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.isLoading = true;
        await this.getSites();
        await this.getDevices();
        this.isLoading = false;
    },
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //====================================================
    methods: {
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        tableCSS() {
            let h = this.windowHeight;
            h = h - 255;
            if (h < 300) h = 300;
            return h + "px";
        },
        //====================================================
        chkAlive(alived_at) {
            if (!alived_at) return false;
            let now = this.$moment().add(-1, "hours");
            let alive = this.$moment(alived_at);
            if (now < alive) return false;
            return true;
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                params: { "_order[id]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { "_order[id]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json;
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                for (let i in this.dbDevices) {
                    let alived_at = this.dbDevices[i].alived_at;
                    if (alived_at) {
                        let alived_at_format = this.$moment(alived_at).format("YYYY-MM-DD HH:mm");
                        this.dbDevices[i].alived_at = alived_at_format;
                    }
                }
                //--------------------------
                // b-table用に組織名を小細工
                for (let i in this.dbDevices) {
                    let site_id = this.dbDevices[i].site_id;
                    this.dbDevices[i].site_name = this.getSiteName(site_id);
                    this.dbDevices[i].site_pref = this.getSitePref(site_id);
                }
                //--------------------------
                // b-table用にGPSを小細工
                for (let i in this.dbDevices) {
                    let gps = this.dbDevices[i].gps;
                    if (gps) {
                        let [lat, lng] = gps.split(",");
                        lat = Number(lat).toFixed(3);
                        lng = Number(lng).toFixed(3);
                        //this.dbDevices[i].gps = { lat: lat, lng: lng };
                        this.dbDevices[i].gps = String(lat) + "," + String(lng);
                    } else {
                        //this.dbDevices[i].gps = { lat: "", lng: "" };
                        this.dbDevices[i].gps = "";
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        getDeviceSensing(device_id) {
            let ret = {};
            for (let idx in this.dbLastSensings) {
                if (device_id == this.dbLastSensings[idx].device_id) {
                    ret = this.dbLastSensings[idx];
                    break;
                }
            }
            return ret;
        },
        //====================================================
        getSiteName(site_id) {
            let site_name = "主要漁場";
            for (let i in this.dbSites) {
                if (site_id == this.dbSites[i].id) site_name = this.dbSites[i].name;
            }
            return site_name;
        },
        //====================================================
        getSitePref(site_id) {
            let site_pref = "主要漁場";
            for (let i in this.dbSites) {
                if (site_id == this.dbSites[i].id) site_pref = this.dbSites[i].pref + " " + this.dbSites[i].city;
            }
            return site_pref;
        }
        //====================================================
    }
};
</script>
