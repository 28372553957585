<template>
    <div class="playback">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <h1 class="p-3">{{ deviceName }}</h1>
        <b-container fluid class="base-color">
            <!-- ####################################### -->
            <b-row>
                <b-col cols="12">
                    <b-form-datepicker v-model="targetDate" @input="getImageList()" :max="maxDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
                </b-col>
                <div class="m-1"></div>
                <b-col cols="12">
                    <b-img :src="jpgImg" fluid-grow></b-img>
                    <pinch-zoom class="pinch-zoom" :properties="pzProps" ref="myPinch" v-show="false"></pinch-zoom>
                    <div class="m-1"></div>
                    <b-card>
                        <h5>{{ targetDate }} {{ dispCursorTime() }}</h5>
                        <b-form-input type="range" min="0" :max="86400 - 1" v-model="cursor_time"></b-form-input>
                        <timeline :imageList="imageList"></timeline>
                    </b-card>
                    <div class="m-1"></div>
                    <b-card>
                        <b-button-group class="m-1">
                            <b-button variant="info" class="mx-1 px-3" @click="slideChange(-900)"><b-icon icon="skip-backward-fill"></b-icon></b-button>
                            <b-button variant="info" class="mx-1 px-3" @click="slideChange(-1)"><b-icon icon="skip-start-fill"></b-icon></b-button>
                            <b-button :variant="isPlay ? 'danger' : 'info'" class="mx-1 px-5" @click="slidePlay()"><b-icon icon="play-fill"></b-icon></b-button>
                            <b-button variant="info" class="mx-1 px-3" @click="slideChange(1)"><b-icon icon="skip-end-fill"></b-icon></b-button>
                            <b-button variant="info" class="mx-1 px-3" @click="slideChange(900)"><b-icon icon="skip-forward-fill"></b-icon></b-button>
                        </b-button-group>
                    </b-card>
                </b-col>
            </b-row>
            <!-- ####################################### -->
        </b-container>
    </div>
</template>

<style scoped>
.navi-orange-on {
    font-weight: bold;
    color: orange; /*文字色*/
    padding: 0.3em 0; /*上下の余白*/
    border-bottom: solid 5px orange; /*線の種類（二重線）太さ 色*/
}
.navi-orange-off {
    font-weight: bold;
    color: #aaaaaa; /*文字色*/
    padding: 0.3em 0; /*上下の余白*/
    border-bottom: solid 5px #dddddd; /*線の種類（二重線）太さ 色*/
}

img {
    background-color: lightgray;
    height: 60vh;
    width: auto;
    /* object-fit: contain; */
    object-fit: contain;
}
.pinch-zoom {
    height: 60vh;
}
</style>

<script>
import JWT from "jsonwebtoken";
import Timeline from "@/components/CompTimeline";
import PinchZoom from "vue-pinch-zoom";
export default {
    name: "Playback",
    components: { Timeline, PinchZoom },
    data() {
        return {
            jwt: "",
            myInfo: [],
            device_id: undefined,
            dbDevices: undefined,
            cursor_time: 0,
            jpgImg: "",
            imageProps: { blank: true },
            imageList: [],
            jpgSec: 1000,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            maxDate: this.$moment().format("YYYY-MM-DD"),
            isPlay: false,
            aspect: "16:9",
            pzProps: {
                //overflow: "visible",
                //disablePan: true,
                limitPan: true,
                zoomControlScale: 2.5,
                doubleTapScale: 4.2,
                doubleTap: true
            },
            isLoading: false,
            postResponse: []
        };
    },
    //===========================================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        this.device_id = this.$route.params.device_id;
        await this.getDevices();
        await this.getImageList();
    },
    //===========================================================================
    beforeDestroy: function() {},
    //===========================================================================
    mounted: function() {
        this.$refs.myPinch.toggleZoom();
    },
    //===========================================================================
    watch: {
        $route: async function(to, from) {
            if (from.fullPath !== to.fullPath) {
                this.device_id = to.params.device_id;
                this.getDevices();
                await this.getImageList();
            }
        }
    },
    //===========================================================================
    computed: {
        deviceName: function() {
            let ret = "";
            for (let idx in this.dbDevices) {
                let id = this.dbDevices[idx].id;
                if (id == this.device_id) ret = this.dbDevices[idx].name;
            }
            return ret;
        }
    },
    //===========================================================================
    methods: {
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        slidePlay: async function() {
            if (this.isPlay == true) {
                this.isPlay = false;
            } else {
                this.isPlay = true;
                while (this.isPlay) {
                    await this.slideChange(+1);
                    await new Promise(r => setTimeout(r, 100));
                    if (this.cursor_time > 86400 - 100) this.isPlay = false;
                }
            }
        },
        //====================================================
        slideChange: async function(seconds) {
            let nextCursor = Number(this.cursor_time) + Number(seconds);
            while (0 < nextCursor && nextCursor < 86400) {
                if (this.imageList[nextCursor]) break;
                if (seconds == 0) break;
                if (seconds > 0) nextCursor++;
                else nextCursor--;
            }
            if (nextCursor < 0) {
                return true;
            }
            if (nextCursor > 86400 - 1) {
                return true;
            }
            this.cursor_time = nextCursor;
            this.getImage();
            //await new Promise(r => setTimeout(r, 5000));
        },
        //====================================================
        dispCursorTime: function() {
            const hh = (this.cursor_time / 3600) | 0; // 小数点切り捨て
            const mm = ((this.cursor_time - hh * 3600) / 60) | 0; // 小数点切り捨て
            const ss = this.cursor_time % 60; // 余り
            return ("00" + hh).slice(-2) + ":" + ("00" + mm).slice(-2) + ":" + ("00" + ss).slice(-2);
        },
        //====================================================
        async getImageList() {
            this.jpgImg = "";
            this.isLoading = true;
            await new Promise(r => setTimeout(r, 1000));
            await this.axios({
                method: "GET",
                url: "/web/api/rapid-image-list/" + this.device_id + "/" + this.targetDate,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.imageList = new Array(86400).fill("");
                    let dateAry = response.data.json;
                    for (let i in dateAry) {
                        let hh = this.$moment(dateAry[i]).hours();
                        let mm = this.$moment(dateAry[i]).minute();
                        let ss = this.$moment(dateAry[i]).second();
                        let aryNo = hh * 3600 + mm * 60 + ss;
                        this.imageList[aryNo] = dateAry[i];
                    }
                    this.isLoading = false;
                    this.isPlay = false;
                    this.cursor_time = 0;
                    this.slideChange(1);
                })
                .catch(err => {
                    this.isLoading = false;
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        getImage() {
            const jpgKey = this.imageList[this.cursor_time];
            if (!jpgKey) {
                this.jpgImg = "";
                this.imageProps = { blank: true, width: 16, height: 9 };
                return false;
            } else {
                this.imageProps = { blank: false };
            }
            this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/rapid-image/" + this.device_id + "/" + jpgKey,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.jpgImg = window.URL.createObjectURL(response.data);
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        }
        //====================================================
    }
};
</script>
