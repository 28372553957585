<template>
    <div class="device-chart">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- ###################################################### -->
        <b-container fluid>
            <b-row>
                <b-col md="5" class="text-left">
                    <h2 class="d-inline">{{ deviceName() }}</h2>
                    <span class="m-2"></span>
                    <router-link :to="{ name: 'DeviceImage', params: { device_id: device_id }, query: { targetDate: targetDate } }">
                        <b-button size="sm" variant="success" class="mb-3">
                            <b-icon icon="grid3x3-gap"></b-icon>
                        </b-button>
                    </router-link>
                </b-col>
                <b-col md="3" class="mb-2">
                    <b-form-datepicker v-model="targetDate" @input="chartReload()" :max="maxDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"></b-form-datepicker>
                </b-col>
                <b-col md="2" class="mb-2">
                    <b-form-select v-model="daySpanValue" :options="daySpanOptions" @change="chartReload()"></b-form-select>
                </b-col>
                <b-col md="2" class="text-right mb-2">
                    <span class="m-1"></span>
                    <b-button v-b-modal.modalSetting variant="danger" size="sm">
                        <b-icon icon="gear"></b-icon>
                    </b-button>
                    <span class="m-1"></span>
                    <b-button variant="info" size="sm" @click="downloadCSV()">
                        <b-icon icon="cloud-download"></b-icon>
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
        <!-- ###################################################### -->
        <b-card class="m-1" border-variant="secondary" bg-variant="secondary" no-body>
            <div class="bg-white m-1">
                <b-row>
                    <b-col class="text-left">
                        <h3 class="bg-secondary text-white" style="width:60px;">装置</h3>
                    </b-col>
                    <b-col>
                        <h4 class="pt-3">{{ dispDate }}</h4>
                    </b-col>
                    <b-col></b-col>
                </b-row>
                <comp-device-chart class="m-2" :style="{ height: chartHeight() }" :chartDataChild="chartDeviceDataSet" :chartOptions="chartDeviceOptions" />
            </div>
        </b-card>
        <!-- ###################################################### -->
        <b-modal id="modalSetting" ref="modalSetting" centered size="xl" title="チャート設定" ok-only @ok="modalOK()" @show="modalReset()" @hidden="modalReset()">
            <b-container fluid>
                <b-row>
                    <b-col lg="6">
                        <b-card>
                            <b-form-group label="【表示項目】">
                                <b-form-checkbox-group v-model="sensorSelected" :options="sensorOptions" switches stacked size="lg" />
                            </b-form-group>
                        </b-card>
                    </b-col>
                    <b-col lg="6">
                        <table class="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th width="40%">項目</th>
                                    <th width="30%">最小値</th>
                                    <th width="30%">最大値</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>水温</td>
                                    <td><b-form-input type="number" v-model="chartRange.water.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.water.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>クロロフィル</td>
                                    <td><b-form-input type="number" v-model="chartRange.chlorophyll.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.chlorophyll.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>濁度</td>
                                    <td><b-form-input type="number" v-model="chartRange.turbidity.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.turbidity.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>塩分濃度</td>
                                    <td><b-form-input type="number" v-model="chartRange.salinity.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.salinity.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>溶存酸素</td>
                                    <td><b-form-input type="number" v-model="chartRange.oxygen.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.oxygen.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>バッテリ電圧</td>
                                    <td><b-form-input type="number" v-model="chartRange.battery.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.battery.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>ソーラー電圧</td>
                                    <td><b-form-input type="number" v-model="chartRange.solar.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.solar.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>筐体温度</td>
                                    <td><b-form-input type="number" v-model="chartRange.raspi_temp.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.raspi_temp.max"></b-form-input></td>
                                </tr>
                                <tr>
                                    <td>CPU温度</td>
                                    <td><b-form-input type="number" v-model="chartRange.raspi_cpu.min"></b-form-input></td>
                                    <td><b-form-input type="number" v-model="chartRange.raspi_cpu.max"></b-form-input></td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
        <!-- ###################################################### -->
    </div>
</template>

<style>
.card-title {
    color: #97a4ba;
}
.card-text {
    color: #67758d;
}
.chart {
    height: 60vh;
}
</style>

<script>
import JWT from "jsonwebtoken";
import CompDeviceChart from "@/components/CompDeviceChart";
export default {
    name: "DeviceChart",
    components: { CompDeviceChart },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            targetDate: this.$moment().format("YYYY-MM-DD"), //targetDate: "2020-08-21",
            maxDate: this.$moment().format("YYYY-MM-DD"),
            device_id: undefined,

            sensorSelected: ["day_night", "water_top", "water_middle", "water_bottom", "water_pressure", "float_sw", "battery", "solar"],
            sensorOptions: [
                { text: "表層水温", value: "water_top" },
                { text: "中層水温", value: "water_middle" },
                { text: "深層水温", value: "water_bottom" },
                { text: "水圧", value: "water_pressure" },
                { text: "フロート", value: "float_sw" },
                { text: "クロロフィル", value: "chlorophyll" },
                { text: "濁度", value: "turbidity" },
                { text: "塩分濃度", value: "salinity" },
                { text: "溶存酸素", value: "oxygen" },
                { text: "昼夜", value: "day_night" },
                { text: "バッテリー", value: "battery" },
                { text: "ソーラー", value: "solar" },
                { text: "筐体温度", value: "raspi_temp" },
                { text: "CPU温度", value: "raspi_cpu" },
                { text: "GPS", value: "gps" }
            ],
            chartRange: {
                water: { min: 0, max: 30 },
                chlorophyll: { min: 0, max: 10 },
                turbidity: { min: 0, max: 10 },
                salinity: { min: 0, max: 10 },
                oxygen: { min: 0, max: 10 },
                battery: { min: 11, max: 13 },
                solar: { min: 0, max: 30 },
                raspi_temp: { min: 0, max: 38 },
                raspi_cpu: { min: 0, max: 80 }
            },
            daySpanValue: 2,
            daySpanOptions: [
                { text: "1日間", value: 1 },
                { text: "2日間", value: 2 },
                { text: "7日間", value: 7 },
                { text: "14日間", value: 14 },
                { text: "30日間", value: 30 }
            ],
            dbDevices: undefined,
            dbSensorLogs: undefined,
            dbData: {
                xyDaytime: [],
                xyWaterTop: [],
                xyWaterMiddle: [],
                xyWaterBottom: [],
                xyWaterPressure: [],
                xyFloatSw: [],
                xyChlorophyll: [],
                xyTurbidity: [],
                xySalinity: [],
                xyOxygen: [],

                xyBattery: [],
                xySolar: [],
                xyCpu: [],
                xyGpsLat: [],
                xyGpsLng: [],
                xyRPiTemp: []
            },
            chartSeaOptions: {},
            chartDeviceOptions: {},
            chartDataSet: {
                // datasets: [
                //     // { data: [], borderColor: "blue" },
                //     // { data: [], borderColor: "lightgray", borderWidth: "5", borderDash: [10, 10], fill: false },
                //     // { data: [], borderColor: "red", borderWidth: "1", fill: false }
                // ]
            },
            chartDeviceDataSet: {
                // datasets: []
            },
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        if (!this.jwt) this.$router.push({ name: "Login" });
        if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
        this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;

        this.modalReset();
        this.isLoading = true;
        this.registSeaChartOptions();
        this.registDeviceChartOptions();
        await this.getDevices();
        await this.getSensorData();
        this.isLoading = false;
    },
    //========================================================
    computed: {
        deviceName3: function() {
            let ret = "";
            for (let idx in this.dbDevices) {
                let id = this.dbDevices[idx].id;
                if (id == this.device_id) ret = this.dbDevices[idx].name;
            }
            return ret;
        },
        dispDate: function() {
            let ret = "";
            let sdays = (this.daySpanValue - 1) * -1;
            ret = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("MM/DD");
            ret += "〜";
            ret += this.$moment(this.targetDate).format("MM/DD");
            return ret;
        }
    },
    //========================================================
    mounted: function() {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function() {
        window.removeEventListener("resize", this.handleResize);
    },
    //========================================================
    methods: {
        //====================================================
        handleResize: function() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        chartHeight() {
            let h = Math.floor(this.windowHeight / 3);
            if (h < 600) h = 600;
            if (this.windowWidth < 500) h = 700; //スマホ縦表示は700px
            return h + "px";
        },
        //====================================================
        deviceName() {
            let ret = "";
            for (let idx in this.dbDevices) {
                let id = this.dbDevices[idx].id;
                if (id == this.device_id) ret = this.dbDevices[idx].name;
            }
            return ret;
        },
        //====================================================
        async chartReload() {
            if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
            this.isLoading = true;
            await new Promise(r => setTimeout(r, 500));
            await this.getSensorData();
            this.isLoading = false;
        },
        //====================================================
        modalReset() {
            if (this.$localStorage.get("chart.sensorSelected.json") != undefined) {
                //this.sensorSelected = JSON.parse(this.$localStorage.get("chart.sensorSelected.json"));
                Object.assign(this.sensorSelected, JSON.parse(this.$localStorage.get("chart.sensorSelected.json")));
            }
            if (this.$localStorage.get("chart.range.json") != undefined) {
                //this.chartRange = JSON.parse(this.$localStorage.get("chart.range.json"));
                Object.assign(this.chartRange, JSON.parse(this.$localStorage.get("chart.range.json")));
            }
        },
        //====================================================
        modalOK() {
            this.registSeaChartOptions();
            this.registDeviceChartOptions();
            this.registSeaChartData();
            this.registDeviceChartData();
            this.$localStorage.set("chart.sensorSelected.json", JSON.stringify(this.sensorSelected));
            this.$localStorage.set("chart.range.json", JSON.stringify(this.chartRange));
            this.$refs["modalSetting"].hide();
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        async getSensorData() {
            //--------------------------------
            let sdays = (this.daySpanValue - 1) * -1;
            //--------------------------------
            // const sdate =
            //     this.$moment(this.targetDate)
            //         .add(sdays, "days")
            //         .format("YYYY-MM-DD") + " 00:00:00";
            // const edate =
            //     this.$moment(this.targetDate)
            //         .add(0, "days")
            //         .format("YYYY-MM-DD") + " 23:59:59";
            //--------------------------------
            const sdate = this.$moment(this.targetDate)
                .add(sdays, "days")
                .format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate)
                .add(+1, "days")
                .format("YYYY-MM-DD");
            //--------------------------------
            let requestURL = "/web/api/sensor_logs";
            requestURL += "?device_id=" + this.device_id;
            requestURL += "&arrived_at[EQGREAT]=" + sdate;
            requestURL += "&arrived_at[SMALL]=" + edate;
            requestURL += "&_order[id]=asc";
            requestURL += "&_fields=sensor_key,sensor_val,arrived_at";
            await this.axios({
                method: "GET",
                url: requestURL,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                this.dbSensorLogs = response.data.json;
                this.registXYdata(sdate, edate);
                this.registSeaChartData();
                this.registDeviceChartData();
            });
        },
        //====================================================
        registXYdata(sdate, edate) {
            //self.console.log(sdate, edate);
            const defaultPoint = [
                { x: sdate, y: null },
                { x: edate, y: null }
            ];
            this.dbData.xyDaytime = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterTop = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterMiddle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterBottom = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterPressure = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyFloatSw = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyChlorophyll = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyTurbidity = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySalinity = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyOxygen = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyBattery = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySolar = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyCpu = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyRPiTemp = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyGpsLat = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyGpsLng = JSON.parse(JSON.stringify(defaultPoint));
            for (let i in this.dbSensorLogs) {
                let x = this.dbSensorLogs[i].arrived_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbSensorLogs[i].sensor_val;
                let s = this.dbSensorLogs[i].sensor_key;
                if (s == "top") this.dbData.xyWaterTop.push({ x: x, y: Number(y) });
                if (s == "middle") this.dbData.xyWaterMiddle.push({ x: x, y: Number(y) });
                if (s == "bottom") this.dbData.xyWaterBottom.push({ x: x, y: Number(y) });
                if (s == "ad3") this.dbData.xyWaterPressure.push({ x: x, y: Number(y) });
                if (s == "s1") this.dbData.xyFloatSw.push({ x: x, y: Number(y) });
                if (s == "chlorophyll") this.dbData.xyChlorophyll.push({ x: x, y: Number(y) });
                if (s == "turbidity") this.dbData.xyTurbidity.push({ x: x, y: Number(y) });
                if (s == "salinity") this.dbData.xySalinity.push({ x: x, y: Number(y) });
                if (s == "oxygen") this.dbData.xyOxygen.push({ x: x, y: Number(y) });

                if (s == "volt") this.dbData.xyBattery.push({ x: x, y: Number(y) });
                if (s == "solar") this.dbData.xySolar.push({ x: x, y: Number(y) });
                if (s == "cpu") this.dbData.xyCpu.push({ x: x, y: Number(y) });
                if (s == "temp") this.dbData.xyRPiTemp.push({ x: x, y: Number(y) });
                if (s == "gps") {
                    let [lat, lng] = y.split(",");
                    this.dbData.xyGpsLat.push({ x: x, y: Number(lat) });
                    this.dbData.xyGpsLng.push({ x: x, y: Number(lng) });
                }
            }
            //--------------------------------
            let dt = "";
            for (let i = 0; i < 30; i++) {
                dt = this.$moment(sdate)
                    .add(i, "day")
                    .format("YYYY-MM-DD");
                if (dt >= edate) break;
                // this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: 0 });
                // this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 0 });
                // this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 50 });
                // this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 50 });
                // this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 0 });
                // this.dbData.xyDaytime.push({ x: dt + " 23:59:00", y: 0 });
                //------------------------------------------------------------
                this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: -100 });
                this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 0 });
                this.dbData.xyDaytime.push({ x: dt + " 12:00:00", y: 100 });
                this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 0 });
            }
            this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: -100 });
        },
        //====================================================
        registSeaChartData() {
            this.chartDataSet = {};
            let tmpDS = [];
            //----------------------
            if (this.sensorSelected.indexOf("water_top") >= 0) {
                tmpDS.push({
                    label: "表層水温",
                    yAxisID: "axis-water-temp",
                    data: this.dbData.xyWaterTop,
                    borderColor: "skyblue",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("water_middle") >= 0) {
                tmpDS.push({
                    label: "中層水温",
                    yAxisID: "axis-water-temp",
                    data: this.dbData.xyWaterMiddle,
                    borderColor: "royalblue",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("water_bottom") >= 0) {
                tmpDS.push({
                    label: "深層水温",
                    yAxisID: "axis-water-temp",
                    data: this.dbData.xyWaterBottom,
                    borderColor: "darkblue",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("water_pressure") >= 0) {
                tmpDS.push({
                    label: "水圧",
                    yAxisID: "axis-water-pressure",
                    data: this.dbData.xyWaterPressure,
                    borderColor: "darkgreen",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("float_sw") >= 0) {
                tmpDS.push({
                    label: "フロート",
                    yAxisID: "axis-float",
                    data: this.dbData.xyFloatSw,
                    borderWidth: 0,
                    fill: true
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("chlorophyll") >= 0) {
                tmpDS.push({
                    label: "クロロフィル",
                    yAxisID: "axis-chlorophyll",
                    data: this.dbData.xyChlorophyll,
                    borderColor: "lightgreen",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("turbidity") >= 0) {
                tmpDS.push({
                    label: "濁度",
                    yAxisID: "axis-turbidity",
                    data: this.dbData.xyTurbidity,
                    borderColor: "brown",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("salinity") >= 0) {
                tmpDS.push({
                    label: "塩分濃度",
                    yAxisID: "axis-salinity",
                    data: this.dbData.xySalinity,
                    borderColor: "gray",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("oxygen") >= 0) {
                tmpDS.push({
                    label: "溶存酸素",
                    yAxisID: "axis-oxygen",
                    data: this.dbData.xyOxygen,
                    borderColor: "orange",
                    borderWidth: 2,
                    fill: false
                });
            }
            //----------------------
            if (this.sensorSelected.indexOf("day_night") >= 0) {
                tmpDS.push({
                    label: "昼夜",
                    yAxisID: "axis-day-night",
                    data: this.dbData.xyDaytime,
                    borderColor: "gold",
                    backgroundColor: "rgba(255, 255, 0, 0.05)",
                    borderWidth: 1,
                    lineTension: 0.5, //「日の出/日の入り」風
                    fill: true
                });
            }
            //----------------------
            this.chartDataSet = JSON.parse(JSON.stringify({ datasets: tmpDS }));
        },
        //====================================================
        registSeaChartOptions() {
            let tmpChartOptions = {
                maintainAspectRatio: false,
                legend: { display: true, position: "bottom" },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            distribution: "linear", // use 'linear'(default) or 'series'
                            time: { displayFormats: { hour: "M月D日 H時", day: "M月D日 H時", month: "M月D日 H時" } },
                            ticks: { minRotation: 10, maxRotation: 40, maxTicksLimit: 9 },
                            gridLines: { drawOnChartArea: true }
                        }
                    ],
                    yAxes: [
                        {
                            display: false,
                            id: "axis-day-night",
                            ticks: { min: 0, max: 100 }
                        },
                        {
                            display: false,
                            id: "axis-float",
                            ticks: { min: 0, max: 1 }
                        },
                        {
                            id: "axis-water-temp",
                            position: "left",
                            scaleLabel: { display: true, labelString: "水温(℃)", fontColor: "navy" },
                            ticks: {
                                min: Number(this.chartRange.water.min),
                                max: Number(this.chartRange.water.max),
                                fontColor: "navy"
                            }
                        },
                        {
                            id: "axis-chlorophyll",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.chlorophyll.min),
                                max: Number(this.chartRange.chlorophyll.max)
                            }
                        },
                        {
                            id: "axis-turbidity",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.turbidity.min),
                                max: Number(this.chartRange.turbidity.max)
                            }
                        },
                        {
                            id: "axis-salinity",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.salinity.min),
                                max: Number(this.chartRange.salinity.max)
                            }
                        },
                        {
                            id: "axis-oxygen",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.oxygen.min),
                                max: Number(this.chartRange.oxygen.max)
                            }
                        },
                        {
                            id: "axis-battery",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.battery.min),
                                max: Number(this.chartRange.battery.max)
                            }
                        },
                        {
                            id: "axis-solar",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.solar.min),
                                max: Number(this.chartRange.solar.max)
                            }
                        },
                        {
                            id: "axis-raspi-temp",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.raspi_temp.min),
                                max: Number(this.chartRange.raspi_temp.max)
                            }
                        },
                        {
                            id: "axis-raspi-cpu",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.raspi_cpu.min),
                                max: Number(this.chartRange.raspi_cpu.max)
                            }
                        },
                        {
                            id: "axis-water-pressure",
                            display: false,
                            ticks: {
                                min: 0,
                                max: 4000
                            }
                        }
                    ]
                },
                tooltips: {
                    enabled: true
                },
                elements: {
                    line: { tension: 0.2 }, // line: { tension: 0.4 },
                    point: { radius: 1, hitRadius: 10, hoverRadius: 1, hoverBorderWidth: 3 }
                },
                animation: false
            };
            this.chartSeaOptions = JSON.parse(JSON.stringify(tmpChartOptions));
        },
        //====================================================
        async registDeviceChartData() {
            this.chartDeviceDataSet = {};
            let tmpDS2 = new Array();

            if (this.sensorSelected.indexOf("battery") >= 0) {
                tmpDS2.push({
                    label: "バッテリ電圧",
                    yAxisID: "axis-battery",
                    //data: this.dbData.xyBattery,
                    data: JSON.parse(JSON.stringify(this.dbData.xyBattery)),
                    borderColor: "red",
                    borderWidth: 2,
                    fill: false
                });
            }
            if (this.sensorSelected.indexOf("solar") >= 0) {
                tmpDS2.push({
                    label: "ソーラー電圧",
                    yAxisID: "axis-solar",
                    //data: this.dbData.xySolar,
                    data: JSON.parse(JSON.stringify(this.dbData.xySolar)),
                    borderColor: "orange",
                    borderWidth: 2,
                    fill: false
                });
            }
            if (this.sensorSelected.indexOf("raspi_cpu") >= 0) {
                tmpDS2.push({
                    label: "CPU温度",
                    yAxisID: "axis-raspi-cpu",
                    //data: this.dbData.xyCpu,
                    data: JSON.parse(JSON.stringify(this.dbData.xyCpu)),
                    borderColor: "green",
                    borderWidth: 2,
                    fill: false
                });
            }
            if (this.sensorSelected.indexOf("raspi_temp") >= 0) {
                tmpDS2.push({
                    label: "筐体温度",
                    yAxisID: "axis-raspi-temp",
                    //data: this.dbData.xyRPiTemp,
                    data: JSON.parse(JSON.stringify(this.dbData.xyRPiTemp)),
                    borderColor: "lightgreen",
                    borderWidth: 2,
                    fill: false
                });
            }
            if (this.sensorSelected.indexOf("gps") >= 0) {
                tmpDS2.push({
                    label: "GPS(緯度)",
                    yAxisID: "axis-gps",
                    data: JSON.parse(JSON.stringify(this.dbData.xyGpsLat)),
                    borderColor: "black",
                    borderWidth: 2,
                    fill: false
                });
                tmpDS2.push({
                    label: "GPS(経度)",
                    yAxisID: "axis-gps",
                    data: JSON.parse(JSON.stringify(this.dbData.xyGpsLng)),
                    borderColor: "navy",
                    borderWidth: 2,
                    fill: false
                });
            }
            if (this.sensorSelected.indexOf("day_night") >= 0) {
                tmpDS2.push({
                    label: "昼夜",
                    yAxisID: "axis-day-night",
                    //data: this.dbData.xyDaytime,
                    data: JSON.parse(JSON.stringify(this.dbData.xyDaytime)),
                    borderColor: "gold",
                    backgroundColor: "rgba(255, 255, 0, 0.05)",
                    borderWidth: 1,
                    lineTension: 0.5, //「日の出/日の入り」風
                    fill: true
                });
            }
            this.chartDeviceDataSet = JSON.parse(JSON.stringify({ datasets: tmpDS2 }));
        },
        //====================================================
        registDeviceChartOptions() {
            let tmpChartOptions = {
                maintainAspectRatio: false,
                legend: { display: true, position: "bottom" },
                scales: {
                    xAxes: [
                        {
                            type: "time",
                            distribution: "linear", // use 'linear'(default) or 'series'
                            time: { displayFormats: { hour: "M月D日 H時", day: "M月D日 H時", month: "M月D日 H時" } },
                            ticks: { minRotation: 10, maxRotation: 40, maxTicksLimit: 9 },
                            gridLines: { drawOnChartArea: true }
                        }
                    ],
                    yAxes: [
                        {
                            display: false,
                            id: "axis-day-night",
                            ticks: { min: 0, max: 100 }
                        },
                        {
                            id: "axis-battery",
                            display: true,
                            ticks: {
                                fontColor: "red",
                                min: Number(this.chartRange.battery.min),
                                max: Number(this.chartRange.battery.max)
                            }
                        },
                        {
                            id: "axis-solar",
                            display: true,
                            ticks: {
                                fontColor: "orange",
                                min: Number(this.chartRange.solar.min),
                                max: Number(this.chartRange.solar.max)
                            }
                        },
                        {
                            id: "axis-raspi-temp",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.raspi_temp.min),
                                max: Number(this.chartRange.raspi_temp.max)
                            }
                        },
                        {
                            id: "axis-raspi-cpu",
                            display: false,
                            ticks: {
                                min: Number(this.chartRange.raspi_cpu.min),
                                max: Number(this.chartRange.raspi_cpu.max)
                            }
                        },
                        {
                            display: false,
                            id: "axis-gps",
                            position: "left",
                            ticks: { suggestedMin: 0, suggestedMax: 2000 }
                        }
                    ]
                },
                tooltips: {
                    enabled: true
                },
                elements: {
                    line: { tension: 0.2 }, // line: { tension: 0.4 },
                    point: { radius: 1, hitRadius: 10, hoverRadius: 1, hoverBorderWidth: 3 }
                },
                animation: false
            };
            this.chartDeviceOptions = JSON.parse(JSON.stringify(tmpChartOptions));
        },
        //====================================================
        downloadCSV() {
            //----------------------------------------------------------------
            let mergeAry = [];
            let i = 0;
            let j = 0;
            //----------------------------------------------------------------
            if (this.sensorSelected.indexOf("water_top") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,表層水温";
                let xyData = this.dbData.xyWaterTop;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("water_middle") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,中層水温";
                let xyData = this.dbData.xyWaterMiddle;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("water_bottom") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,深層水温";
                let xyData = this.dbData.xyWaterBottom;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("water_pressure") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,水圧";
                let xyData = this.dbData.xyWaterPressure;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("float_sw") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,フロート";
                let xyData = this.dbData.xyFloatSw;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("chlorophyll") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,クロロフィル";
                let xyData = this.dbData.xyChlorophyll;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("turbidity") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,濁度";
                let xyData = this.dbData.xyTurbidity;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("salinity") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,塩分濃度";
                let xyData = this.dbData.xySalinity;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (this.sensorSelected.indexOf("oxygen") >= 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,溶存酸素";
                let xyData = this.dbData.xyOxygen;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            if (i > 0) {
                j = 0;
                mergeAry[i] = [];
                mergeAry[i][j++] = "日付,バッテリ";
                let xyData = this.dbData.xyBattery;
                for (let k in xyData) {
                    if (xyData[k].y !== null) {
                        mergeAry[i][j++] = String(xyData[k].x) + "," + String(xyData[k].y);
                    }
                }
                i++;
            }
            //----------------------------------------------------------------
            // 一番長い配列を求める
            //----------------------------------------------------------------
            let maxRow = 0;
            for (let i in mergeAry) {
                if (maxRow < mergeAry[i].length) maxRow = mergeAry[i].length;
            }
            //----------------------------------------------------------------
            // 連番をふる。
            //----------------------------------------------------------------
            mergeAry.unshift(new Array(maxRow));
            for (let i = 0; i < maxRow; i++) {
                mergeAry[0][i] = i;
            }
            //----------------------------------------------------------------
            // 配列の数を揃える
            //----------------------------------------------------------------
            for (let i in mergeAry) {
                let rowCt = mergeAry[i].length;
                let sbn = maxRow - rowCt;
                if (sbn > 0) {
                    mergeAry[i] = mergeAry[i].concat(new Array(sbn).fill(","));
                }
            }
            //----------------------------------------------------------------
            //  デバッグ配列
            //----------------------------------------------------------------
            // mergeAry = [];
            // mergeAry[0] = ["T-111", "T-222", "T-333", "T-444", "T-555"];
            // mergeAry[1] = ["M-111", "M-222"];
            // mergeAry[2] = ["M-111", "M-222"];
            // mergeAry[3] = ["B-111", "B-222", "B-333", "B-444", "B-555", "B-666"];
            //----------------------------------------------------------------
            // 二次元配列の行列を転置
            //----------------------------------------------------------------
            const transpose = a => a[0].map((_, c) => a.map(r => r[c]));
            mergeAry = transpose(mergeAry);
            //----------------------------------------------------------------
            //  配列をCSV化
            //----------------------------------------------------------------
            let csv = "\ufeff" + this.deviceName() + "\n";
            for (let i in mergeAry) {
                csv += mergeAry[i].join(",") + "\n";
            }
            let blob = new Blob([csv], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "umilog.csv";
            document.body.appendChild(link);
            link.click();
        }
        //====================================================
        // downloadCSV_BK() {
        //     let csv = "\ufeff" + this.deviceName() + "\n";
        //     //------------------
        //     if (this.sensorSelected.indexOf("water_top") >= 0) {
        //         csv = csv + "日付,表層水温\n";
        //         let xyData = this.dbData.xyWaterTop;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("water_middle") >= 0) {
        //         csv = csv + "日付,中層水温\n";
        //         let xyData = this.dbData.xyWaterMiddle;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("water_bottom") >= 0) {
        //         csv = csv + "日付,深層水温\n";
        //         let xyData = this.dbData.xyWaterBottom;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("water_pressure") >= 0) {
        //         csv = csv + "日付,水圧\n";
        //         let xyData = this.dbData.xyWaterPressure;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("float_sw") >= 0) {
        //         csv = csv + "日付,フロートスイッチ\n";
        //         let xyData = this.dbData.xyFloatSw;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("chlorophyll") >= 0) {
        //         csv = csv + "日付,クロロフィル\n";
        //         let xyData = this.dbData.xyChlorophyll;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("turbidity") >= 0) {
        //         csv = csv + "日付,濁度\n";
        //         let xyData = this.dbData.xyTurbidity;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("salinity") >= 0) {
        //         csv = csv + "日付,塩分濃度\n";
        //         let xyData = this.dbData.xySalinity;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     if (this.sensorSelected.indexOf("oxygen") >= 0) {
        //         csv = csv + "日付,溶存酸素\n";
        //         let xyData = this.dbData.xyOxygen;
        //         for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     }
        //     csv = csv + "日付,バッテリー\n";
        //     let xyData = this.dbData.xyBattery;
        //     for (let k in xyData) if (xyData[k].y !== null) csv += String(xyData[k].x) + "," + String(xyData[k].y) + "\n";
        //     //------------------
        //     let blob = new Blob([csv], { type: "text/csv" });
        //     let link = document.createElement("a");
        //     link.href = window.URL.createObjectURL(blob);
        //     link.download = "umilog.csv";
        //     document.body.appendChild(link);
        //     link.click();
        //     // link.parentNode.removeChild(link);
        // }
        //====================================================
    }
};
</script>
