<template>
    <div class="devices-camera">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <h1 class="p-3">デバイス カメラ</h1>
        <b-container fluid>
            <b-row>
                <b-col cols="4">
                    <b-form-group><b-form-input v-model="filter" type="search" placeholder="キーワードフィルター"></b-form-input></b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3" v-for="(item, idx) in lastImages" :key="idx" v-show="nameFilter(item.site_pref + item.site_name + item.device_name)">
                    <b-card no-body class="mb-3" border-variant="dark" header-border-variant="dark">
                        <template #header>
                            <b-row>
                                <b-col cols="8" class="text-left">
                                    <h5 class="mb-0">{{ item.device_name }}</h5>
                                    <h6 class="small">
                                        {{ item.site_pref }} <br />
                                        {{ item.site_name }} <br />
                                        {{ item.date }}
                                    </h6>
                                </b-col>
                                <b-col cols="4">
                                    <router-link :to="{ name: 'DeviceChart', params: { device_id: item.device_id } }" v-show="false">
                                        <b-button size="sm" variant="success" class="m-1">
                                            <b-icon icon="graph-up"></b-icon>
                                        </b-button>
                                    </router-link>
                                    <router-link :to="{ name: 'DeviceImage', params: { device_id: item.device_id } }">
                                        <b-button size="sm" variant="success" class="m-1">
                                            <b-icon icon="grid3x3-gap"></b-icon>
                                        </b-button>
                                    </router-link>
                                    <router-link :to="{ name: 'Playback', params: { device_id: item.device_id } }">
                                        <b-button size="sm" variant="danger" class="m-1">
                                            <b-icon icon="collection-play-fill"></b-icon>
                                        </b-button>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </template>
                        <b-card-body class="p-0">
                            <b-aspect :aspect="aspect">
                                <b-img fluid :src="item.jpgImg" v-b-modal.modalDeviceJpg @click="modalUpdate(idx)" />
                            </b-aspect>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-show="loadingMax > loadingCount">
                    <b-button block variant="outline-info" @click="imageLoad()">さらに表示</b-button>
                </b-col>
            </b-row>
        </b-container>
        <!-- =========================== -->
        <b-modal id="modalDeviceJpg" size="xl" hide-footer>
            <template #modal-title>
                <h5 class="mb-0">{{ modalDevice.name }}</h5>
                <h6 class="mb-0 small">{{ modalDevice.date }}</h6>
            </template>
            <b-aspect :aspect="aspect"><b-img fluid-grow :src="modalDevice.jpgImg"/></b-aspect>
        </b-modal>
        <!-- =========================== -->
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
export default {
    name: "DevicesCamera",
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            lastImages: [
                //{ jpgImg: "", date: "", device_id: "", device_name: "", site_name: "", site_pref: ""}
            ],
            bufferImages: [],
            filter: null,
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: ""
            },
            aspect: "16:9",
            loadingCount: 0,
            loadingMax: 0,
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("jwt");
        this.myInfo = JWT.decode(this.jwt);
        if (!this.jwt) this.$router.push({ name: "Login" });
        if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
        this.isLoading = true;
        await this.getSites();
        await this.getDevices();
        await this.imageLoad();
        //await this.getLastImage(3034);
        //await this.getLastImage(3035);
        this.isLoading = false;
    },
    //========================================================
    watch: {},
    //========================================================
    methods: {
        //====================================================
        nameFilter(device_name) {
            if (!this.filter) return true;
            if (device_name.indexOf(this.filter) == -1) return false;
            return true;
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                //params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.dbSites = response.data.json;
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { is_active: 1, "_order[id]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(async response => {
                    this.dbDevices = response.data.json;
                    this.loadingMax = this.dbDevices.length;
                    // for (let idx in this.dbDevices) {
                    //     let device_id = this.dbDevices[idx].id;
                    //     let device_name = this.dbDevices[idx].name;
                    //     let site_name = this.getSiteName(this.dbDevices[idx].site_id);
                    //     let site_pref = this.getSitePref(this.dbDevices[idx].site_id);
                    //     this.getLastImage(device_id, device_name, site_name, site_pref);
                    // }
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        async imageLoad() {
            let startCount = this.loadingCount;
            let endCount = this.loadingCount + 8;
            this.loadingCount = this.loadingCount + 8;
            for (let idx in this.dbDevices) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.dbDevices[idx].id;
                    let device_name = this.dbDevices[idx].name;
                    let site_name = this.getSiteName(this.dbDevices[idx].site_id);
                    let site_pref = this.getSitePref(this.dbDevices[idx].site_id);
                    //this.getLastImage(device_id, device_name, site_name, site_pref);
                    this.lastImages.push({
                        device_id: device_id,
                        jpgImg: "", //window.URL.createObjectURL(response.data),
                        date: "---", //response.headers["x-image-date"],
                        device_name: device_name,
                        site_name: site_name,
                        site_pref: site_pref
                    });
                }
            }
            for (let idx in this.lastImages) {
                if (startCount <= idx && idx < endCount) {
                    let device_id = this.lastImages[idx].device_id;
                    this.getLastImage(device_id, this.lastImages[idx]);
                }
            }
        },
        //====================================================
        getLastImage(device_id, imgObj) {
            this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/last-image/" + device_id + "/",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    imgObj.jpgImg = window.URL.createObjectURL(response.data);
                    imgObj.date = response.headers["x-image-date"];
                    //this.lastImages = Object.assign([], this.lastImages);
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        },
        //====================================================
        modalUpdate(idx) {
            this.modalDevice.id = this.lastImages[idx].device_id;
            this.modalDevice.name = this.lastImages[idx].device_name;
            this.modalDevice.date = this.lastImages[idx].date;
            this.modalDevice.jpgImg = this.lastImages[idx].jpgImg;
        },
        //====================================================
        getSiteName(site_id) {
            let site_name = "主要漁場";
            for (let i in this.dbSites) {
                if (site_id == this.dbSites[i].id) site_name = this.dbSites[i].name;
            }
            return site_name;
        },
        //====================================================
        getSitePref(site_id) {
            let site_pref = "主要漁場";
            for (let i in this.dbSites) {
                if (site_id == this.dbSites[i].id) site_pref = this.dbSites[i].pref + " " + this.dbSites[i].city;
            }
            return site_pref;
        }
        //====================================================
    }
    //========================================================
};
</script>
