<template>
    <div>
        <div class="outer-div">
            <div class="inner-div">
                <b-img :src="LogoImg" rounded="circle" thumbnail width="150"></b-img>
                <div class="m-3"></div>
                <b-form>
                    <b-input-group class="mb-3">
                        <b-form-input type="text" v-model="username" placeholder="アカウント" />
                    </b-input-group>
                    <b-input-group class="mb-3">
                        <b-form-input type="password" v-model="password" placeholder="パスワード" />
                    </b-input-group>
                    <b-button block variant="primary" @click="jwtauth()">ログイン</b-button>
                </b-form>
                <h6 class="text-white text-right mt-3">Ver {{ ver }}</h6>
            </div>
        </div>
    </div>
</template>

<style>
.outer-div {
    display: table;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(steelblue, royalblue);
}
.inner-div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 50px;
    padding-bottom: 200px;
}
</style>

<script>
import Version from "@/version.json";
export default {
    name: "Login",
    //==========================================
    data() {
        return {
            ver: Version.version,
            dbVersion: [],
            LogoImg: require("@/assets/sfsLogo.jpg"),
            username: "",
            password: "",
            dbUsers: []
        };
    },
    //==========================================
    created: function() {},
    //==========================================
    mounted: function() {
        this.getVersion();
    },
    //==========================================
    methods: {
        //-----------------------------------------
        getVersion() {
            this.axios({
                method: "GET",
                url: "/web/versions",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 3000
            }).then(response => {
                this.dbVersion = response.data.json[0];
                if (this.dbVersion.pro_version) {
                    if (this.dbVersion.pro_version > this.ver) {
                        if (confirm("新しいバージョンがあります。\n更新しますか？")) {
                            this.reloadApp();
                        }
                    }
                }
            });
        },
        //-----------------------------------------
        // バージョンアップ（サービスワーカーを解除し、リロードで再登録する）
        reloadApp() {
            window.navigator.serviceWorker.getRegistrations().then(registrations => {
                for (let registration of registrations) registration.unregister();
            });
            window.location.reload(true);
        },
        //-----------------------------------------
        jwtauth() {
            this.axios({
                method: "POST",
                url: "/web/api/authenticate",
                data: {
                    username: this.username.trim(),
                    password: this.password.trim()
                },
                timeout: 3000 // 3000ms
            })
                .then(response => {
                    let jwt = response.data.token;
                    this.dbUsers = response.data.json;
                    if (jwt) {
                        this.$localStorage.set("jwt", jwt);
                        this.$router.push({ path: "/" });
                    } else {
                        alert("登録がありません。");
                    }
                })
                .catch(error => {
                    alert("認証エラー");
                    self.console.log(error);
                });
        }
        //-----------------------------------------
    }
    //==========================================
};
</script>
