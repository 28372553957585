<template>
    <div id="app">
        <!--
        <div id="nav">
            <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link>
        </div>
        -->
        <common-header />
        <div style="margin-top: 56px;">&nbsp;</div>
        <router-view />
        <div class="p-5">&nbsp;</div>
        <!-- <common-footer /> -->
    </div>
</template>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>

<script>
import CommonHeader from "@/components/CommonHeader";
// import CommonFooter from "@/components/CommonFooter";
export default {
    components: {
        // CommonFooter,
        CommonHeader
    }
};
</script>
